.adminDash{
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 1px solid #888;
    border-radius: 15px;
    padding: .5rem;
    // height: fit-content;
    top:0;
    position: sticky;
    background: white;

    .mobile-content, 
    .menu-container{
        display: none;
    }


    .logo-container{
        display: flex;
        text-decoration: none;
        color: #523d35;
        // margin-left: 1rem;
        align-items: center;
        
        img{
            width: 50px;
            height: 50px;
            padding-left: .8rem;
        }

        .logo{
            letter-spacing: 1px;
            display: flex;
            gap: 2px;
            font-weight: 1000;
            font-family: "Recursive", sans-serif;
            p{
            }
        }
    }

    .adminDash-links{
        margin-top: 1rem;
        padding-right: 2rem;

        a{
            text-decoration: none;
            color: #000;
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-left: 1.2rem;
            padding: .7rem;
            border-radius: 10px;
            transition: .2s ease-in-out;
            

            &:hover{
                background: hsl(17, 21%, 90%);
                transform: scale(.95);
            }

            .dash-icon, 
            .admin-shop-icon,
            .admin-masterclass-icon{
                font-size: 25px;
                border-radius: 50%;
            }

            .admin-pic{
                height: 40px;
                width: 40px;
                border-radius: 50%;
                object-fit: cover;

            }

            p{
                font-weight: 600;
            }

            h3{
                font-weight: 500;
            }

           
        }

        .adminDash-shop,
        .adminDash-masterclass{
            h2{
                margin-bottom: 1rem;
                margin-left: 2rem;
                margin-top: 1rem;
                font-weight: 600;
            }
        }

        .adminDash-shop{
            margin: 1rem 0;
        }
        


        .adminDash-masterclass{
            margin-bottom: 1rem;
            // height: 100%;
            margin-top: 5.5rem;
            display: flex;
            // flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            bottom: 0;

            
            a{
                width: 100%;
                background: hsl(17, 21%, 90%);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            }

           

            .admin-masterclass-icon{
                width: 30px;
            }
        }
    }

    
}

@media screen and (max-width: 1080px) {
.adminDash{
    width: fit-content;
    padding: 0;
    position: fixed;
    background: white;
    z-index: 10;
    margin-top: 1rem;


    .menu-container{
        display: block;
        position: relative;
        justify-content: center;
        border-radius: 15px;
        
    }

    .menu-icon {
        width: fit-content; 
        cursor: pointer;
        display: inherit;
        justify-content: center;
        align-items: center;
        padding: .5rem;
        padding-bottom: .3rem;
      }

      .mobile-admin-menu{
        font-size: 25px;
        padding: .5rem;
        transition: .3s ease-in-out;
        justify-content: center;
        align-items: center;

        &:hover{
            transform: scale(.85);
        }
      }
      
      .menu-border {
        height: 0;
        transition: height 0.5s ease;
      }
      
      .menu-links {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        

        a{
            text-decoration: none;
            color: #000;
            margin-bottom: .5rem;
            padding: .5rem;
            border-radius: 10px;
            transition: .3s ease-in-out;
            position: relative;


            h3{
                background: hsl(17, 21%, 90%);
                position: absolute;
                left: 0%;
                top: 50%;
                transform: translateY(-50%);
               display: inline-block;
               white-space: nowrap;
               width: fit-content;
              margin-left: 150%;
              padding: 5px;
              border-radius: 8px;
              font-weight: 700;
              font-size: 13px;
                display: none;                
            }

            .admin-shop-icon,
            .admin-masterclass-icon {
                font-size: 25px;
            }

            .adminPic{
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

            &:nth-child(1){
                margin-top: 1rem;
            }

            &:hover{
                background: hsl(17, 21%, 90%);
                transform: scale(.85);
            }

            &:hover h3{
                display: block;
            }
        }


      }
      
      .menu-container.open .menu-border {
        height: 20.69rem; /* Adjust to desired expanded height */
      }

      .menu-container.open{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      }
      
      .menu-container.open .menu-links {
        opacity: 1;
        pointer-events:  auto;
      }
   

    .desktop{
        display: none;
    }

     .logo-container{
        display: none;
    }

}
}



@media screen and (min-width: 1080px) and (max-width: 1115px) {
.adminDash{
    

    .adminDash-links{
        font-size: .8em;
    }
}
}

@media screen and (min-width: 1080px) {
    .adminDash{
        height: 91vh;
    }
}