.home{
    overflow: hidden;
    width: 100vw;
  
    .popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0 , 0, 0.5);
        z-index: 10000;

        .spinner {
            position: absolute;
            width: 9px;
            height: 9px;
          }
          
          .spinner div {
            position: absolute;
            width: 50%;
            height: 150%;
            background: white;
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
          }
          
          .spinner div:nth-child(1) {
            --delay: 0.1;
            --rotation: 36;
            --translation: 150;
          }
          
          .spinner div:nth-child(2) {
            --delay: 0.2;
            --rotation: 72;
            --translation: 150;
          }
          
          .spinner div:nth-child(3) {
            --delay: 0.3;
            --rotation: 108;
            --translation: 150;
          }
          
          .spinner div:nth-child(4) {
            --delay: 0.4;
            --rotation: 144;
            --translation: 150;
          }
          
          .spinner div:nth-child(5) {
            --delay: 0.5;
            --rotation: 180;
            --translation: 150;
          }
          
          .spinner div:nth-child(6) {
            --delay: 0.6;
            --rotation: 216;
            --translation: 150;
          }
          
          .spinner div:nth-child(7) {
            --delay: 0.7;
            --rotation: 252;
            --translation: 150;
          }
          
          .spinner div:nth-child(8) {
            --delay: 0.8;
            --rotation: 288;
            --translation: 150;
          }
          
          .spinner div:nth-child(9) {
            --delay: 0.9;
            --rotation: 324;
            --translation: 150;
          }
          
          .spinner div:nth-child(10) {
            --delay: 1;
            --rotation: 360;
            --translation: 150;
          }
          
          @keyframes spinner-fzua35 {
            0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            }
          
            50% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
            }
          }
    }

    .checkout-popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: rgba(0, 0 , 0, 0.5);


        .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1.5rem;
                text-align: center;
                background: white;
                padding: 5rem 3rem 3rem 3rem;
                border-radius: 1rem;
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: 2.5rem;

                    .a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #228B22;
                        color: white;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                        &:hover{
                            transform: scale(.9)
                        }

                     
                    }

                    .again{
                        background: #FF4C4C;
                    }
                }

              .error{
                background: #FF4C4C;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }

                .ticket-icon{
                    font-size: 3rem;
                }
              } 

              .success{
                background: #228B22;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }

                .ticket-icon{
                    font-size: 3rem;
                }
              } 

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }
    }


.landing{
    background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/landing.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1.5rem;
        margin: 15rem 0;
    
    h1{
        width: 80%;
        color: white;
        letter-spacing: 1px;
        font-size: xx-large;
        line-height: 1.5;
    }

    p{
        color: white;
        font-size: 14px;
        font-weight: 500;
    }

    .cta{
        padding: 10px 20px;
        border-radius: 20px;
        outline: none;
        border: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: .4rem;
        transition: .3s ease;
        background: transparent;
        border: 1px solid white;
        border-radius: 5px;
        
        &:hover{
           transform: scale(.95);
            }
            
            .sspan{
                text-decoration: none;
                color: white;
        }


    }

     }
        
    }


    .tickets{
        margin-top: 5rem;
    background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/2024.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    position: relative;

    .landing-content-container{
        margin: 5rem 0;

        .button button .tickets-span{
            color: white;;text-decoration: none;
        }
    }
    }

    .shop-section,
    .masterclass-section,
    .about-section, 
    .newsletter-section,
    .this-year-section,
    .sponsors-section{
        width: 90vw;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .this-year-section{
        
        .this-year-container{
        display: grid;
        grid-template-columns: 50% 50%;
        // gap: 2.5rem;

        .left{
            display: grid;
            // gap: 1.5rem;

            .top{
                width: 90%;
                justify-content: center;
                // height: 25em;

                img{
                max-width: 100%;
                height: auto;
                }
    
                // background: url('../../stock/backgrounds/6-removebg.png')no-repeat;
                // background-size: cover;
                // background-position: center;
                // background-blend-mode: overlay;
                // border-radius: 5px;
            }

            .bottom{
                line-height: 1.5;
            }
        }

            .right{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .block{
                    display: flex;
                    gap: 1.6rem;

                    &:nth-child(3){
                        h3{
                            padding: 0;
                            }
                    }

                    h3{
                        line-height: 1.5;
                        font-size: .9em;
                        padding-top: .5rem;
                        display: flex;
                        gap: .5rem;
                        align-items: center;
                        justify-content: flex-start;
                        padding-bottom: .5rem;
                    }

                    p{
                        line-height: 1.5;
                        padding-bottom: .4rem;
                    }

                    a{
                        text-decoration: none;
                        color: #FF7E00;
                        padding-bottom: .5rem;
                        border-bottom:1px solid #FF7E00;
                        width: fit-content;
                        font-weight: 600;
                        transition: 0.3s ease-in-out;
                        font-size: .8em;
    
                        &:hover{
                            border-bottom: 1px solid #bba58f;
                            color: hsl(17, 21%, 46%);
                            
                        }
                    }

                    .img{
                        min-width: 150px;
                        height: 150px;
                        background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/location.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;
                        border-radius: 10px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                    }
                    .imgtwo{
                        background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/049A2024.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;
                    }
                    .imgthree{
                        background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/crew.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;

                    }

                    
                }
            }

        }

    }

    .shop-section{

        h1{
        margin-bottom: .7rem;
        font-weight: 500;
        }

        h5{
            margin-top: .7rem;
        }

        .shop-items{
            &::-webkit-scrollbar {
                display: none; /* Or width: 0; or height: 0; */
              }
        overflow-x: auto;

            margin-top: 2rem;
            display: flex;
            gap: 2rem;

            a{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-width: 200px;
                max-width: 200px;
                height: 350px;
                text-decoration: none;
                border-radius: 5px;
                padding: 1.5rem 2rem;

                h2{
                color: white;
                font-weight: 400;
                }

                h3{
                color: white;
                font-size: smaller;
                letter-spacing: 1px;
                font-weight: 400;

                }
            }
            
            .clm{
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;
            }

            .one{
                background: rgba(0, 0, 0, 0.3) url('../../stock/grid/standingstill.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundOne 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundOne {
                        0% { background-image: url('../../stock/grid/backbacking.jpg'); }
                        50% { background-image: url('../../stock/grid//holdinghoodie.jpg'); }
                        100% { background-image: url('../../stock/grid/standingstill.jpg') }
                      }
            }

            .two{
                background: rgba(0, 0, 0, 0.3) url('../../stock/grid/sobunsomze.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundTwo 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundTwo {
                        0% { background-image: url('../../stock/grid/haedwear.jpg'); }
                        50% { background-image: url('../../stock/grid/sobunsomze.jpg')}
                        100% { background-image:url('../../stock/grid/redbeanie.jpg');}
                      }
            }

            .three{
                background: rgba(0, 0, 0, 0.3) url('../../stock/grid/slides.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundThree 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundThree {
                        0% { background-image: url('../../stock/grid/holdingslides.jpg'); }
                        50% { background-image: url('../../stock/grid/slides.jpg'); }
                        100% { background-image: url('../../stock/grid/slidepair.jpg'); }
                      }
            }

            .four{
                background: rgba(0, 0, 0, 0.3)  url('../../stock/grid/vybezpack.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundFour 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundFour {
                        0% { background-image:  url('../../stock/grid/vybezpack.jpg'); }
                        50% { background-image:  url('../../stock/grid/somzesquating.jpg'); }
                        100% { background-image:  url('../../stock/grid/blackholding\ bag.jpg'); }
                      }
            }

            .five{
                background: rgba(0, 0, 0, 0.3) url('../../stock/grid/shirtnshort.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundFive 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundFive {
                        0% { background-image: url('../../stock/grid/blackonblack.jpg'); }
                        50% { background-image: url('../../stock/grid/shirtnshort.jpg') }
                        100% { background-image:url('../../stock/grid/whiteshirt.jpg');  }
                      }
            }

        }

        .shop-cta{
            padding: 10px 20px;
            border-radius: 20px;
            outline: none;
            border: none;
            font-weight: 600;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: .4rem;
            transition: ease;
            margin-top: 2rem;
            background: #FF7E00;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
            &:hover{
                gap: .7rem;
            }

            .shop-span, .ctaArrow{
            color: white;
            text-decoration: none;
        }


        }
     
    }

    .masterclass-section{

        .m-container{
            display: grid;
            grid-template-columns: 45% 50%;
            place-items: center;
            justify-content: space-between;

            .m-sec-left{
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                width: 90%;
                justify-content: center;
                height: 25em;            

                .left-box{
                    height: 7rem;
                    width: 75%;
                    border-radius: 15px;
                   background-size: cover;
                    background-position: center;
                background-blend-mode: overlay;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                     &:nth-child(1){
                        display: flex;
                        align-self: center;
                        background: url('../../stock/backgrounds/DSC_3180.jpg')no-repeat;
                        background-size: cover;
                        background-position: center ;
                    background-blend-mode: overlay;
                    }

                    &:nth-child(2){
                        display: flex;
                        align-self: flex-end;
                background: url('../../stock/backgrounds/049A2103.jpg')no-repeat;
                background-size: cover;
                background-position: center ;
            background-blend-mode: overlay;
                    }

             
                    &:nth-child(3){
                        background: url('../../stock/backgrounds/049A1918.jpg')no-repeat;
                        background-size: cover;
                        background-position: center ;
                    background-blend-mode: overlay;
                    }

                }
            }

            .m-sec-right{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h1{
                    span{
                        color: #FF7E00;
                    }
                }

                p{
                    line-height: 1.5;
                }

                a{
                    width: fit-content;
                    padding: 8px 20px;
                    border-radius: 25px;
                    outline: none;
                    border: none;
                    font-weight: 600;
                    display: flex;
                    cursor: pointer;
                    gap: .4rem;
                    transition: .2s ease-in-out;
                    align-items: center;
                    text-decoration: none;
                    background: #FF7E00;
                    color: white;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            
                    &:hover{
                        gap: .7rem;
                    }
            
                }
            }
        }
    }

    .about-section{

        .abt-sec-container{

            display: grid;
            grid-template-columns: 50% 50%;
            place-items: center;
            justify-content: space-between;


            .abt-sec-left{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h1{
                    span{
                        color: #FF7E00;
                    }
                }

                p{
                    line-height: 1.5;
                }

                a{
                    text-decoration: none;
                    color: #FF7E00;
                    padding-bottom: .5rem;
                    border-bottom:1px solid #FF7E00;
                    width: fit-content;
                    font-weight: 600;
                    transition: 0.3s ease-in-out;

                    &:hover{
                        border-bottom: 1px solid hsl(30, 100%, 70%);
                        color: hsl(30, 100%, 70%);
                        
                    }
                }
            }

            .abt-sec-right{
                width: 90%;
                justify-content: center;
                height: 25em;

                background: url('../../stock/backgrounds/crowd.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                border-radius: 5px;
            }
        }

    }

    .newsletter-section{
        text-align: center;
        margin-bottom: 3rem;

        .newsletter-container{
            width: 70%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            
                span{
                    color: #FF7E00;
                }
            

            p{
                margin-bottom: 1rem;
                line-height: 1.5;
            }

            .sub-container{
                position: relative;
                // border: 1px solid #888;
                padding: .7rem 1rem;
                border-radius: 5.5rem;
                display: flex;
                margin: 0 auto;
                gap: 4rem;
                width: fit-content;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border: 1px solid #888;
            
                input{
                    // padding: 1rem 0;
                    outline: none;
                    border: none;
                }

                button{
                    padding: 8px 15px;
                    border: none;
                    background: #FF7E00;
                    border-radius: 20px;
                    color: white;
                    cursor: pointer;
                    font-weight: 600;
                    transition: .3s ease-in-out;

                    &:hover{
                        transform: scale(.95);
                    }
                }
            }

            h6{
                margin-top: 1rem;
            }
        }

    }

    .sponsors-section{

        h1{
            margin-bottom: .7rem;
            font-weight: 500;
            }

            h3{
                color: #323232;
                margin-top: 2rem;
            }

            a{
                text-decoration: none;
                color: #FF7E00;
                padding-bottom: .5rem;
                border-bottom:1px solid #FF7E00;
                width: fit-content;
                font-weight: 600;
                transition: 0.3s ease-in-out;
                font-size: .8em;

                &:hover{
                    border-bottom: 1px solid #bba58f;
                    color: hsl(17, 21%, 46%);
                    
                }
            }

            .sponsors-container{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 2rem;

                img{
                    width: 20%;
                }
            }
             
    }

    .addNumber{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 100000;
        background: rgba(0, 0 , 0, 0.5);

        .checkout-container{
            display: inherit;
            flex-direction: inherit;
            justify-content: inherit;
            align-items: inherit;
            gap: 1rem;
            text-align: center;
            background: white;
            padding: 5rem 3rem 3rem 3rem;
            border-radius: 1rem;
            

            p{
                font-weight: 500;
                color: #333;
                line-height: 1.5;
                width: 30vw;

                span{
                    font-weight: 600;
                }
            }

            .buttons{
                // margin-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                margin-top: 2.5rem;

                .a{
                    padding: 15px 8px;
                    border-radius: 25px;
                    border: none;
                    cursor: pointer;
                    width: 250px;
                    font-weight: 600;
                    text-decoration: none;
                    transition: .3s ease;
                    background: #888;
                    color: white;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                    &:hover{
                        transform: scale(.9)
                    }

                 
                }

                a{
                    text-decoration: none;
                }

                .again{
                    background: #FF7E00;
                }
            }

          
    }
    }

}

@media screen and (max-width: 1080px) {
.home{

    .landing{
        
        .landing-content-container{
            width: 90%;
            
            p{
                line-height: 1.5;
            }

            h1{
                width: 100%;
            }
        }
    }

    .shop-items{
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none; /* Or width: 0; or height: 0; */
          }
    }

    .masterclass-section{

        .m-container{
            display: flex;
            flex-direction: column-reverse;
            gap: 3rem;

            .m-sec-left{
                width: 100%;
            }
        }
    }

    .about-section{

        .abt-sec-container{
            display: flex;
            flex-direction:column;
            gap: 3rem;

            .abt-sec-right{
                width: 100%;
            }
        }
    }

    .this-year-section{

        .this-year-container{
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .left .top{
                width: 100%;
            }

            .right{
                gap: 2.5rem;

             .block{
                display: flex;
                align-items: center;
                 .img{
                     height: 75px;
                     width: 5px;
                    }

                    &:nth-child(3){
                        h3{
                            padding-bottom: .5rem;

                            }
                    }
                }

            }
        }
    }
} 
}

@media screen and (max-width: 500px) {
.home{

    .landing {
        height: 100vh;
    }

    .tickets{
    height:fit-content ;

    .landing-content-container{
    h1{
    font-size: 27.5px;
    }
}
    }
    
    .landing-content-container{
        // margin: 16rem 0
        // padding-top: 2rem;
    }
}
}

@media screen and (max-width: 720px) {
  .newsletter-section{
    position: relative;
      
      .newsletter-container{
        min-width: 100%;

        h1{
            font-size: 1.3rem;
        }

        p{
            font-size: .8em
        }

        .sub-container{
            gap: 2rem!important;
        }
    } 
    
}

.this-year-section{

    .this-year-container{
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .right{

         .block{
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
             .img{
                 height: 75px;
                 width: 5px;
                }
            }

        }
    }
}

.checkout-popup{

    .checkout-container{
        width: 60vw;
        font-size: 90%;
        padding: 3rem 3rem 3rem 3rem;

    }
}

.home{
    .addNumber{


        .checkout-container{
        width: 80vw;
        gap: 0;
        padding: 0;
        padding: 1.5rem;
    
        p{
            width: 90%;
        }
    
        .buttons{
            margin-top: 1rem;
            width: 100%;
    
            .a{
                width: 100%;
            }
        }
        }
    }
}

}