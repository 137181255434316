.contact{
    width: 100%;

    .contact-header{
        background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/contact.jpg')no-repeat;
        background-size: cover;
        background-position: center 40%;
        background-blend-mode: overlay;
        position: relative;
        position: relative;
        display: flex;
        align-items: center;
        
        
        .header{
            display: flex;
            align-items: center;
            overflow-x: hidden;
            justify-content: space-between;
            position: relative;
            overflow-x: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding: 2rem 5rem;
            padding: 2rem 0 2rem 3rem;
            padding-top: 7rem;
            animation: fadeIn 1s ease-in-out;

        }
        h1{
            color: white;
            letter-spacing: 1px;
        }
    }

    .contact-header.tickets{
        background: rgba(0, 0, 0, 0.5) url('../../stock/grid/tick.jpg')no-repeat;
        background-size: cover;
        background-position: center 30%;
        background-blend-mode: overlay;
        position: relative;
        position: relative;
        display: flex;
        align-items: center; 
    }

    .popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0 , 0, 0.5);
        z-index: 10000;

        .spinner {
            position: absolute;
            width: 9px;
            height: 9px;
          }
          
          .spinner div {
            position: absolute;
            width: 50%;
            height: 150%;
            background: white;
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
          }
          
          .spinner div:nth-child(1) {
            --delay: 0.1;
            --rotation: 36;
            --translation: 150;
          }
          
          .spinner div:nth-child(2) {
            --delay: 0.2;
            --rotation: 72;
            --translation: 150;
          }
          
          .spinner div:nth-child(3) {
            --delay: 0.3;
            --rotation: 108;
            --translation: 150;
          }
          
          .spinner div:nth-child(4) {
            --delay: 0.4;
            --rotation: 144;
            --translation: 150;
          }
          
          .spinner div:nth-child(5) {
            --delay: 0.5;
            --rotation: 180;
            --translation: 150;
          }
          
          .spinner div:nth-child(6) {
            --delay: 0.6;
            --rotation: 216;
            --translation: 150;
          }
          
          .spinner div:nth-child(7) {
            --delay: 0.7;
            --rotation: 252;
            --translation: 150;
          }
          
          .spinner div:nth-child(8) {
            --delay: 0.8;
            --rotation: 288;
            --translation: 150;
          }
          
          .spinner div:nth-child(9) {
            --delay: 0.9;
            --rotation: 324;
            --translation: 150;
          }
          
          .spinner div:nth-child(10) {
            --delay: 1;
            --rotation: 360;
            --translation: 150;
          }
          
          @keyframes spinner-fzua35 {
            0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            }
          
            50% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
            }
          }
    }

    .checkout-popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: rgba(0, 0 , 0, 0.5);


        .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1.5rem;
                text-align: center;
                background: white;
                padding: 5rem 3rem 3rem 3rem;
                border-radius: 1rem;
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: 2.5rem;

                    .a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #228B22;
                        color: white;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                        &:hover{
                            transform: scale(.9)
                        }

                     
                    }

                    .again{
                        background: #FF4C4C;
                    }
                }

              .error{
                background: #FF4C4C;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }
              } 

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }
    }


    .contact-form, 
    .about-touch{
        width: 90vw;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .contact-form{
        display: grid;
        grid-template-columns: 40% 50%;
        justify-content: space-between;

        .form-left{
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h1{
                span{
                    color: #FF7E00;
                }
            }

            p{
                line-height: 1.5;
            }

            .contact-page-cta{
                width: fit-content;
                display: flex;
                align-items: center;
                text-decoration: none;
                
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: .7rem;
                transition: .2s ease;
                background: #FF7E00;
                color: white;
                
                &:hover{
                    color: #333;
                        background: #F0F0F0;
                        gap: 1rem;
                    }
    
                    .cta-form{
                        font-size: 20px;
                    }
                
            }

            h6{
                color: #bba58f;
            }
        }

        .form-right{
            padding: 1.5rem;

            form{
                display: flex;
                flex-direction: column;
                justify-content: center;

                .top{
                    display: flex;
                    gap: 2rem;
                    margin-bottom: 2rem;

                    input{
                        padding: 1rem .5rem;
                        border: none;
                        border-bottom: 1px solid #8888;
                        outline: none;

                        &:nth-child(2){
                            padding-right: 5rem;
                        }


                    }

                }

                .bottom{
                    margin-bottom: 2rem;
                    width: 80.5%;
                    

                    textarea{
                        resize: none;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #8888;
                        width: 100%;
                    }



                }

                button{
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    gap: 1rem;
                    padding: 10px 15px;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                    transition: .3s ease;

                    &:hover{
                        transform: scale(0.9);
                        background: #FF7E00;
                        color: white;
                    }

                    h3{
                        font-weight: 600;
                    }

                    .submit-btn{
                        font-size: 20px;
                    }
                }

                .form-socials{
                    margin-top: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    font-size: 1.5rem;
                  width: 80.5%;
                  
                  a{
                      text-decoration: none;
                      color: #333;
                }

                  .icon{
                    cursor: pointer;
                    transition: .3s ease;


                    &:hover{
                        transform: scale(0.9);
                    }
                  }
                }


            }
        }
    }

    .Faqs{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        margin:0 auto;
        margin-top:4rem;
        width: 90%;
    
        h1{
            font-weight: 500;
        }
      
        .acc-wrap{
            padding: 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
    
            input{
                position: absolute;
                opacity: 0;
                z-index: -1;
            }
    
            .accordion{
                width: 90%;
                display:grid;
                grid-template-columns: 50% 50%;
                gap: 4rem;
    
    
            }
    
            .item{
                margin-bottom: 20px;
                border-radius: 8px;
                overflow: hidden;
                padding-top: 2rem;
    
            }
    
            .title{
                background: white;
                display: flex;
                padding: 1rem;
                justify-content: space-between;
                color: #252525;
                font-weight: 600;
                cursor: pointer;
    
                &::after{
                    content: "\002B";
                    font-size: 20px;
                    width: 1rem;
                    height: 1rem;
                    text-align: center;
                    transition: .3s ease-in-out;
                }
            }
    
            .acc-content{
                background: white;
                padding: 0 1rem;
                color: #828282;
                line-height: 2;
                max-height: 0;
                transition: .3s ease-in-out;
                border-bottom: 1px solid #200b0b;
    
            }
    
            input:checked + .title:after{
                transform: rotate(90deg);
            }
    
            input:checked + label + .acc-content{
                max-height: 100vh;
                padding: 1rem;
            }
        }
       
          
    }

    .about-touch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .8rem;
        margin-top: 6rem;
        text-align: center;


        .about-page-cta{
            width: fit-content;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #333;
            background: #F0F0F0;
            
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: .7rem;
                transition: .2s ease-in-out;
        
                &:hover{
                    gap: 1rem;
                    background: #FF7E00;
                    color: white;
                }

                .cta-insta{
                    font-size: 20px;
                }
            
        }
    }

}

@media screen and (max-width: 1080px) {
.contact{

    .contact-header{

        .header{
            padding: 1rem 0.5rem 2rem 2rem;
            padding-top: 7rem;

            h1{
                font-size: 1.5em;
            }
        }
    }

    .contact-form{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;

        .form-left{
            width: 85vw;
            margin: 0 auto;
            margin-bottom: 2rem;
        }

        .form-right{
            form{
                .top{
                    flex-direction: column;
                }

                .bottom,
                .form-socials{
                    width: 100%;
                }

            }
        }
    }


    .Faqs{

        h1{
    
            text-align: center;
        }
    
       .acc-wrap .accordion{
            display:flex;
            flex-direction: column;
            gap: 0;
        }
    }
}
}

@media screen and (max-width: 720px) {
    .contact{
    
        .contact-header{
    
            .header{
                padding: 1rem 0 2rem 1.5rem;
                padding-top: 7rem;
    
                h1{
                    font-size: 1em;
                }
            }
        }

        .about-touch{

            h1{
                font-size: 1.25rem;
            }
        }

        .checkout-popup{

            .checkout-container{
                width: 60vw;
                font-size: 90%;
                padding: 3rem 3rem 3rem 3rem;

            }
        }
    }
}