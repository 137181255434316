.contact{
    width: 100%;

    .contact-header{
        background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/contact.jpg')no-repeat;
        background-size: cover;
        background-position: center 40%;
        background-blend-mode: overlay;
        position: relative;
        position: relative;
        display: flex;
        align-items: center;
        
        
        .header{
            display: flex;
            align-items: center;
            overflow-x: hidden;
            justify-content: space-between;
            position: relative;
            overflow-x: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding: 2rem 5rem;
            padding: 2rem 0 2rem 3rem;
            padding-top: 7rem;
            animation: fadeIn 1s ease-in-out;

        }
        h1{
            color: white;
            letter-spacing: 1px;
        }
    }

    .contact-header.tickets{
        background: rgba(0, 0, 0, 0.5) url('../../stock/grid/tick.jpg')no-repeat;
        background-size: cover;
        background-position: center 30%;
        background-blend-mode: overlay;
        position: relative;
        position: relative;
        display: flex;
        align-items: center; 
    }

    .popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0 , 0, 0.5);
        z-index: 10000;

        .spinner {
            position: absolute;
            width: 9px;
            height: 9px;
          }
          
          .spinner div {
            position: absolute;
            width: 50%;
            height: 150%;
            background: white;
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
          }
          
          .spinner div:nth-child(1) {
            --delay: 0.1;
            --rotation: 36;
            --translation: 150;
          }
          
          .spinner div:nth-child(2) {
            --delay: 0.2;
            --rotation: 72;
            --translation: 150;
          }
          
          .spinner div:nth-child(3) {
            --delay: 0.3;
            --rotation: 108;
            --translation: 150;
          }
          
          .spinner div:nth-child(4) {
            --delay: 0.4;
            --rotation: 144;
            --translation: 150;
          }
          
          .spinner div:nth-child(5) {
            --delay: 0.5;
            --rotation: 180;
            --translation: 150;
          }
          
          .spinner div:nth-child(6) {
            --delay: 0.6;
            --rotation: 216;
            --translation: 150;
          }
          
          .spinner div:nth-child(7) {
            --delay: 0.7;
            --rotation: 252;
            --translation: 150;
          }
          
          .spinner div:nth-child(8) {
            --delay: 0.8;
            --rotation: 288;
            --translation: 150;
          }
          
          .spinner div:nth-child(9) {
            --delay: 0.9;
            --rotation: 324;
            --translation: 150;
          }
          
          .spinner div:nth-child(10) {
            --delay: 1;
            --rotation: 360;
            --translation: 150;
          }
          
          @keyframes spinner-fzua35 {
            0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            }
          
            50% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
            }
          }
    }

    .checkout-popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: rgba(0, 0 , 0, 0.5);


        .checkout-container.tickets{
                display: inherit;
                flex-direction: inherit;
                align-items: inherit;
                gap: 1.5rem;
                background: white;
                // padding: 5rem 3rem 3rem 3rem;
                border-radius: 1rem;
                position: relative;

                h1{
                  display: flex;
                  align-self: center;
                }

                ul{
                position: relative;
                  line-height: 2;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  font-weight: 500;
                  margin-left: 7%;
                }
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons.tickets{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: .5rem;

                    .a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #FF7E00;
                        color: white;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                        &:hover{
                            transform: scale(.9)
                        }

                     
                    }

                    .again{
                        background: #FF4C4C;
                    }
                }

              .error{
                background: #FF4C4C;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                h1{
                    font-size: 3.5rem;
                }
              } 

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }

        .ticketID{
          display: inherit;
          flex-direction: inherit;
          align-items: inherit;
          gap: 1.5rem;
          background: white;
          padding: 3rem 3rem 3rem 3rem;
          border-radius: 1rem;
          position: relative; 

         h3, p{
            line-height: 1.5;
            text-align: center;
          }

          .button {
            padding: 10px 20px;
            font-size: 16px;
            color: #fff;
            background-color: #004494;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-weight: 500;
            transition: background-color 0.3s, transform 0.3s;
          }
          
          .button:hover {
            background-color: #0056b3;
            transform: scale(1.05);
          }
          
          .button:active {
            background-color: #004494;
          }
          
          .copied {
            background-color: #28a745;
          }
          

          .buttons{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            margin-top: .5rem;

            .a{
                width: 100%;
                padding: 15px 8px;
                border-radius: 25px;
                border: none;
                cursor: pointer;
                width: 250px;
                font-weight: 600;
                text-decoration: none;
                transition: .3s ease;
                background: #FF7E00;
                color: white;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                &:hover{
                    transform: scale(.9)
                }

             
            }

            .again{
                background: none;
                border: 1px solid #888;
                width: 75%;
                justify-self: center;
                color: black;
            }
          }

          .checkbox-wrapper {
            position: relative;
            display: inline-block;
            width: 90px;
            height: 90px;
          }
          
          .checkbox-wrapper .background {
            fill:#228B22;
            transition: ease all 0.6s;
            -webkit-transition: ease all 0.6s;
          }
          
          .checkbox-wrapper .stroke {
            fill: none;
            stroke: #fff;
            stroke-miterlimit: 10;
            stroke-width: 2px;
            stroke-dashoffset: 100;
            stroke-dasharray: 100;
            transition: ease all 0.6s;
            -webkit-transition: ease all 0.6s;
          }
          
          .checkbox-wrapper .check {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 3px;
            stroke-dashoffset: 22;
            stroke-dasharray: 22;
            animation: dash 2s ease infinite;
          }
          
          @keyframes dash {
            0% {
              stroke-dashoffset: 22;
            }
            50% {
              stroke-dashoffset: 0;
            }
            100% {
              stroke-dashoffset: 22;
            }
          }
          
          .checkbox-wrapper input[type=checkbox] {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            margin: 0;
            opacity: 0;
            appearance: none;
            -webkit-appearance: none;
          }
          
          .checkbox-wrapper input[type=checkbox]:hover {
            cursor: pointer;
          }
          
          .checkbox-wrapper input[type=checkbox]:checked + svg .background {
            fill: #228B22;
          }
          
          .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
            stroke-dashoffset: 0;
          }
          
          .checkbox-wrapper input[type=checkbox]:checked + svg .check {
            stroke-dashoffset: 0;
            animation: none;
          }
        }
    }

    .ticket-form{
        width: 90vw;
        margin: 0 auto;
        margin-top: 2.5rem;

        display: none;
    }

    .ticket-form{
    // display: flex;
    justify-content: center;
    align-items: center;

        .form-right{
            padding: 1.5rem;
            
            .head{
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1.5rem;
                line-height: 1.5;

                h2{
                  display: flex;
                  align-items: center;
                  gap: 5px;
                }

                .ticket-icon{
                  color: #FF7E00;
                }
            }

            .package-container{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1.6rem;
                margin-block: 1.5rem;

                .package {
                    padding: 20px;
                    // margin: 10px;
                    border: 2px solid #ccc;
                    cursor: pointer;
                    transition: background 0.3s, color 0.3s;
                    border-radius: 5px;
                  }
                  
                  .package.selected {
                    // background-color: #4CAF50;
                    background: #FF7E00;
                    background: linear-gradient(to right, hsl(30, 100%, 55%), #FF7E00);
                    color: white;
                    border-color: #FF7E00;
                    font-weight: 500;
                  }

                  .package.noquantity{
                    display: none;

                  }
                  .package.quantity{
                    display: block;
                    .quantity-selector{
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .qty{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                      }

                      button{
                        padding-inline: 10px;
                        background: #FF7E00;
                        color: white;
                        font-weight: 600;
                        cursor: pointer;
                        font-size: 1rem;
                        border: none;
                        border-radius: 5px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                      }
                    }
                    
                  }
            }

            form{
                display: flex;
                flex-direction: column;
                justify-content: center;
                // position: relative;

                .top{
                  margin-bottom: 1.5rem;
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 2rem;
                  // display: none;

                    input{
                        padding: 1rem .5rem;
                        border: none;
                        border-bottom: 1px solid #8888;
                        outline: none;

                        &:nth-child(2){
                            padding-right: 5rem;
                        }


                    }

                    input:focus {
                        border-color: #FF7E00; 
                        outline: none; 
                        transform: scale(.95);
                        transition: .2s ease-in-out;
                    }

                }

                .bottom{
                    margin-bottom: 2rem;
                    width: 100%;
                    position: relative;

                    label {
                        display: block;
                        margin-bottom: 8px;
                        font-weight: 500;
                        color: #333;
                    }
            
                    select {
                        width: 100%;
                        padding: 8px;
                        font-size: 16px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        margin-bottom: 1.5rem;
                        transition: .2s ease;
                        // transform: scale(.95);
                    }

                    select:focus, input:focus {
                        border-color: #FF7E00; 
                        outline: none; 
                        transform: scale(.95);
                        transition: .2s ease-in-out;
                    }
                    
                    input{
                        // padding: 1rem .5rem;
                        // padding-right: 0;
                        padding: 1rem 0 1rem 0;
                        border: none;
                        border-bottom: 1px solid #8888;
                        outline: none;
                        position: relative;
                        width: 100%;
                        margin-bottom: 1.5rem;

                        &::placeholder{
                            padding-left: .5rem;
                        }

                    }

                    textarea{
                        resize: none;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #8888;
                        width: 100%;
                    }



                }

                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 1rem;
                    padding: 10px 15px;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                    transition: .3s ease;
                    background: #FF7E00;
                    color: white;
                    text-align: center;

                    &:hover{
                        transform: scale(0.95);
                    }

                    h3{
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                    }

                    .submit-btn{
                        font-size: 20px;
                    }
                }



            }
        }
    }

}

@media screen and (max-width: 1080px) {
.contact{

    .contact-header{

        .header{
            padding: 1rem 0.5rem 2rem 2rem;
            padding-top: 7rem;

            h1{
                font-size: 1.5em;
            }
        }
    }

  


    .Faqs{

        h1{
    
            text-align: center;
        }
    
       .acc-wrap .accordion{
            display:flex;
            flex-direction: column;
            gap: 0;
        }
    }
}
}

@media screen and (max-width: 720px) {
    .contact{
    
        .contact-header{
    
            .header{
                padding: 1rem 0 2rem 1.5rem;
                padding-top: 7rem;
    
                h1{
                    font-size: 1em;
                }
            }
        }

        .about-touch{

            h1{
                font-size: 1.25rem;
            }
        }

        .checkout-popup{

            .checkout-container.tickets{

              h1{
                font-size: 1.25rem;
                line-height: 1.5;
              }

                width: 65%;
                font-size: 90%;
                // padding: 3rem 3rem 3rem 3rem;
                padding: 2.5rem;
              font-size: .8em;
              text-align: left;
              
              ul{
                position: relative;
                  line-height: 2;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  font-weight: 500;
                  margin-left: 7%;
                }
            }
        }
    }
}

@media screen and (max-width: 625px) {
.contact{


  .ticket-form{

    .form-right{
  
      .package-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      form{
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        .top{
          grid-template-columns: repeat(1, 1fr);

        }

        button{
          h3{
            display: flex;
            align-items: center;
            gap: .5rem;
          }
        }
      }
    }
  }
}
}

@media screen and (max-width: 575px) {
.contact{
  .checkout-popup{
    .ticketID{
      padding: 2rem;
      font-size: .8em;
      width: 70%;

      .button{
      font-size: .9em;
      }

      .buttons{
        width: 100%;

        .a{
        font-size: .9em;
          width: 75%;
        }
        .again{
          width: 50%;
        }
      }
    }
  }
}
}