.onboarding{
    width: 90vw;
    margin: 0 auto;
    margin-block: 3rem;

        .onboarding-spinner{
        padding: 1px 1rem;
        margin: 0 1rem;
        animation: spin 2s linear infinite;


        @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
    }

    .upload-username{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        align-items: center;

        // .progress{
        //     position: relative;
        // display: flex;
        // gap: 3rem;
        // margin-bottom: 3rem;
        // justify-content: center;


        // .bar{
        //     position: absolute;
        //     top: 20%;
        //     width: 77%;
        //     height: 1px;
        //     background: hsl(0, 0%, 73%);

        //     left: 50%;
        //     transform: translateX(-50%);

        // }

        // span{
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     gap: .5rem;

        //     .completed-icon{
        //         color: green;
        //     }

        //     h4{
        //         font-weight: 400;
        //         font-size: 15px;
        //     }

        //     p{
        //         padding-inline: 5px;
        //         padding-block: 1px;
        //         background: white;
        //         z-index: 2; 
        //     }

        //     .current-page{
        //             border: 1px solid #888;
        //             border-radius: 50%;
        //     }
        // }
        // }

        .upload-Username-container{
            display: inherit;
            flex-direction: inherit;
            justify-content: center;
            align-items: center;
               height: 80vh;


            h2{
                font-weight: 400;
                margin: 2rem 0;
                // font-size: 18px;
            }

            input{
                padding: 1rem;
                border-radius: 8px;
                outline: none;
                border: none;
                background: hsl(0, 0%, 93%);
            }

            button{
                margin: 1rem 0;
                width: fit-content;
                background: black;
                color: white;
                border: none;
                padding: 10px 8px;
                border-radius: 8px;
                cursor: pointer;
                transition: .2s ease-in-out;

                &:hover{
                    transform: scale(.95);
                }
            }

            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
.onboarding{

    .upload-username{

        .progress{
            width: 95vw;
            overflow-x: hidden;

            .bar{
                width: 70%;
            }

            span{

                h4{
                    font-size: 10px;
                    text-align: center;
                }
            }
        }
    }
}

}
