.masterclass{
    width: 100%;

    .masterclass-details,
    .masterclass-structure,
    .masterclass-benefits,
    .about-touch{
        width: 90vw;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .head-tag{
        width: 92.5vw;
        margin: 0 auto;
        padding-block: 2rem;
        font-size: 85%;
        
    }
    
    .masterclass-landing{
        background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/memories.jpg')no-repeat;
        background-size: cover;
        background-position: center 60%;
        background-blend-mode: overlay;
        position: relative;
        display: flex;
        align-items: center;

        
        .header{
            display: flex;
            align-items: center;
            overflow-x: hidden;
            justify-content: space-between;
            position: relative;
            overflow-x: hidden;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding: 2rem 5rem;
            padding: 2rem 0 2rem 3rem;
            padding-top: 7rem;
            animation: fadeIn 1s ease-in-out;

        }
        h1{
            color: white;
            letter-spacing: 1px;
        }

    }

    .masterclass-enroll{
        width: 100%;
        overflow-x: hidden;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90dvw;
        margin: 0 auto;
        margin-top: 2rem;

        .enroll-left{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 50%;
            color: #000;
          

            h1,h3{
                span{
                    color: #FF7E00;
                }
            }

            h1{
                font-size: 2.25rem;
                width: 90%;
            }

            p{
                line-height: 1.7;
                color: #000;
            }

            .class-cta{
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: .5rem;

                a{
                    display: inherit;
                    align-items: inherit;
                    gap: .5rem;
                    color: #eee;
                    text-decoration: none;
                    border: 1px solid #888;
                    padding: 7px 15px;
                    border-radius: 20px;
                    transition: .3s ease-in-out;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

                        
                        &:hover{
                            transform: scale(.95);
                        }

                        &:nth-child(1){
                            background: #000;
                            color: white;
                            border: none;
                            padding: 9px 15px;
                        }

                }
            }
        }

        .enroll-right{
            width: 50%;
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 1rem;
            
            .pic{
               box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            }

            .one{
                background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/victory.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
            }
            .two{
                background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/warmer.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
            }
            .three{
                background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/grace.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
            }
            .four{
                background: rgba(0, 0, 0, 0.1) url('../../stock/backgrounds/annie.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
            }
            
            .pic-left,.pic-right{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                
                .long{
                    width: 225px;
                    height: 175px;
                    border-radius: 25px;
                }

                .wide{
                    height: 225px;
                    width: 175px;
                    border-radius: 25px;
                }
            }

            .pic-left{
                display: flex;
                justify-content: left;
                align-items: flex-end;
            }
        }


    }

    .activities{
    width: 90dvw;
    margin: 0 auto;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 40% 50%;
    justify-content: center;
    align-items: center;

    .activities-left{
    position: relative;

    .box{
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #eee;

    &:nth-child(1){
    width: 300px;
    height: 300px;
    z-index: 0;
    background: url('../../stock/backgrounds/omalu2.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    }

       &:nth-child(2){
    width: 215px;
    height: 250px;
    position: absolute;
    bottom: -5rem;
    left: 10rem;
    z-index: 1;
    background: url('../../stock/backgrounds/facepaint.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    }

      &:nth-child(3){
    width: 150px;
    height: 150px;
    position: absolute;
    top: 2rem;
    left: 16rem;
    z-index: 2;
       background: url('../../stock/backgrounds/games.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    }

    }

    .activities-right{
        display: flex;
        flex-direction: column;
        line-height: 1.5;

        h1{
            padding-top: 5rem;
            span{
                color: #FF7E00;
            }
        }

        p{
            line-height: 1.7;
        }

        .more-pics{
            display: flex;
            align-items: center;
            margin-top: 1rem;
            gap: 1.5rem;
            justify-content: center;

            .pic{
                border: 1px solid hsl(0, 0%, 73%);
                width: 200px;
                height: 125px;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;    

                &:nth-child(1){
                    background: url('../../stock/backgrounds/fun.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                }

                &:nth-child(2){
                    background: url('../../stock/backgrounds/fun3.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                }

                &:nth-child(3){
                    background: url('../../stock/backgrounds/fun2.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                }
            }
        }
    }

    }

    .see-more{
        width: 90dvw;
        margin: 0 auto;
        margin-top: 7rem;
        display: none;

        h2{
            text-align: center;
            // font-weight: 600;
            line-height: 1.5;

            span{
                color: #FF7E00;
            }
        }

        .see-more-container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
            margin-top: 2rem;
            
            .left, .middle, .right{
                // border: 1px solid blue;
            }

            .left{
                display: grid;
                grid-template-columns: 30% 70%;
                position: relative;

                .left-top{
                    width: 125px;
                    height: 75px;
                    border-radius: 20px;
                    position: absolute;
                    bottom: -90px;
                    background: url('../../stock/backgrounds/grid1.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                }
                .left-bottom{
                    width: 225px;
                    height: 175px;
                    border-radius: 20px;
                    position: relative;
                    left: 135%;
                   background: url('../../stock/backgrounds/grid2.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
                }
            }

            .middle{
                display: flex;
                justify-content: center;
                position: relative;

                .middle-only{
                    width: 90%;
                    border-radius: 20px;
                    position: absolute;
                    height: 125%;
                    background: url('../../stock/backgrounds/middle.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                }
            }

            .right{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .right-top{
                    display: flex;
                    // justify-content: space-between;
                    align-items: flex-end;
                    gap: 5rem;
                    

                    .right-top-1{
                        width: 125px;
                        height: 125px;
                        border-radius: 30px;
                        background: url('../../stock/backgrounds/grid4.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    }
                    .right-top-2{
                        width: 75px;
                        height: 75px;
                        border-radius: 20px;
                        background: url('../../stock/backgrounds/grid3.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    }
                }

                .right-bottom{
                    display: flex;
                    justify-content: center;
                    position: relative;

                    .right-bottom-only{
                        position: absolute;
                        width: 165px;
                        height: 150px;
                        border-radius: 30px;
                        bottom: -160px;
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        background: url('../../stock/backgrounds/grid5.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                        margin-right: 4rem;
                    }
                }
            }
        }

    }

    .about-touch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .8rem;
        margin-top: 9rem;


        .about-page-cta{
            width: fit-content;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #333;
            background: #F0F0F0;
            
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: .7rem;
                transition: .2s ease-in-out;
        
                &:hover{
                    gap: 1rem;
                    background: #FF7E00;
                    color: white;
                }

                .cta-insta{
                    font-size: 20px;
                }
            
        }
    }
    



}



@media screen and (max-width: 1080px) {
.masterclass{


    .masterclass-landing{
        // background-position: center 1%;


        .header{
            padding: 1rem 0.5rem 2rem 2rem;
            padding-top: 7rem;

            h1{
                font-size: 1.5em;
            }
        }
    }

    .masterclass-enroll{
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        .enroll-left{
        width: 100%;

        h1{
        width: 100%;

        }
        }
    }

    .masterclass-details{

        .m-learn{
            flex-direction: column;
        }
    }


    .masterclass-benefits{

        .benefits-container{
            grid-template-columns: repeat(2, 1fr);
        }
    }

 
}

}

@media screen and (max-width: 720px) {
.masterclass{

    .masterclass-landing{

        .header{
            padding: 1rem 0 2rem 1.5rem;
            padding-top: 7rem;

            h1{
                font-size: 1em;
            }
        }
    }

    .masterclass-enroll{
        justify-content: center;
    
        .enroll-left{
            width: 100%;
            font-size: 80%;
        }

         .enroll-right{

            .pic-left,.pic-right{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                
                .long{
                    width: 200px;
                    height: 150px;
                    border-radius: 25px;
                }

                .wide{
                    height: 200px;
                    width: 150px;
                    border-radius: 25px;
                }
            }

            .pic-left{
                display: flex;
                justify-content: left;
                align-items: flex-end;
            }
        }
    }
   
}

.head-tag{
    h2{
        font-size: 85%;
    }

}



}

@media screen and (max-width: 1115px) {
.activities{
    display: grid;
    grid-template-columns: 50% 50% !important;
}
}

@media screen and (max-width: 1020px) {
.activities{
    display: flex !important;
   flex-direction: column-reverse;
    // border: 1px solid red;
    text-align: center;
    // font-size: .8em;

    .activities-left{
        margin-top: 2rem;
        border: 1px solid red;
        width: 90vw; 
        display: grid;
        justify-content: center;
        display: none;
        
        
        .box{
           
        &:nth-child(1){
            width: 150px !important;
            height: 200px !important;
        }

          &:nth-child(2){
            width: 115px !important;
            height: 150px !important;
            left: 8rem !important;
        }

             &:nth-child(3){
            width: 75px !important;
            height: 75px !important;
            top: 5rem !important;
            left: 13rem !important;
        }
    }
    }

    .activities-right{

        h1{
            padding-top: 0 !important;
        }

        width: 100%;
        .more-pics{
        display: grid;
        grid-template-columns: repeat(3,1fr) ;
        position: relative;
        gap: .5rem !important;
        }
    }

}
}