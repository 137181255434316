.adminHome{
    width: 95%;
    margin: 0 auto;
    display: grid;
    position: relative;
    // grid-template-columns: 200px 1fr;
    grid-template-columns: 25% auto;
    gap: 2rem;
    margin-top: 1rem;
    max-height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;

    .mobile-content{
        display: none;
    }
    
    &::-webkit-scrollbar {
        overflow-x: hidden; 
        display: none;
    }

    
    .adminContent{
        width: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .tix{
            display: flex;
         flex-direction: column;   
            gap: 1rem;
        }
    }
}


@media screen and (max-width: 1080px) {
.adminHome{
    display: flex;
    flex-direction: column;

    .mobile-content{
        display: block;
    }
    .desktop-content{
        display: none;
    }

    .admin-current-page{
        margin-block: 1rem;
        text-align: right;
        padding-right: 2rem;
    }

    .adminContent{
        margin-top: .5rem;
    }

    &::-webkit-scrollbar {
        overflow-x: hidden; 
        display: none;
    }
}
}