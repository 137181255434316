/* src/CountdownTimer.css */
.NavCountdown {
    display: grid;
    // grid-template-columns: repeat(4,1fr);
    display: flex;
    margin: 0 1rem;
    // justify-content: center;
    // align-items: center;
    // gap: 1rem;
    // height: 100vh;
    font-size: 1.25rem;
    font-weight: bold;
    // font-family: 'Courier New', Courier, monospace;
    color: #fff;
    // background: linear-gradient(45deg, #ff6b6b, #f06595, #00b4d8, #6a4c93);
    // background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    // text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    // background: rgba(2, 2, 2, 0.001);
    // backdrop-filter: blur(5px);

  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .span{
    color: #eee;
    // border: 1px solid #eee;
    width: 5rem;
    justify-content: center;
    align-items: center;
    padding-inline: 1rem;
    gap: 2rem;
    border-inline: 1px solid #888;

    &:nth-child(1){
      border-left: none;
    }

    &:nth-child(4){
      border-right: none;
    }
  }
  
  .NavCountdown span {
    // margin: 0 10px;
    animation: fadeIn 1s ease-in-out;
    display: flex;
    gap: 5rem;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // width: fit-content;
    // justify-content: center;
    // align-items: center;

    &:nth-child(2){
        // border-right: 1px solid #888;
        // padding-right: 1rem;
    }
    &:nth-child(3){
        // border-right: 1px solid #888;
        // padding-right: 1rem;
    }   &:nth-child(1){
        // border-right: 1px solid #888;
        // padding-right: 1rem;
    }
    
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

@media screen and (max-width: 660px) {
.NavCountdown{
    font-size: .8em;
    margin: 0 ;
}

.span{
padding-inline: 5px;
width: 3rem;
}

// .NavCountdown span {
//     // margin: 0 10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     animation: fadeIn 1s ease-in-out;
//     text-align: center;
//     // border: 1px solid  red;

//     &:nth-child(2){
//         border-right: 1px solid #888;
//         // padding-right: .5rem;
//     }
//     &:nth-child(3){
//         border-right: 1px solid #888;
//         // padding-right: .5rem;
//     }   &:nth-child(1){
//         border-right: 1px solid #888;
//         // padding-right: .5rem;
//     }
    
//   }

}
  