@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300..1000&display=swap');
.navigation{
    display: flex;
    justify-content: center;
    position: relative;
    // display: none;
    }

.navbar{
    width: 95vw;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    // box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border: 0.5px solid #8888;
    border-radius: 25px;
    margin-top: .8rem;
    margin-bottom: 2em;
    position: fixed;
    top: 0;
    z-index: 5;
    background: #fff;


    .mobile-view{
        display: none;
    }

    .active-link{

        &::after{
            position: absolute;
            bottom: -30%;
            left: 35%;
            margin: 0 auto;
            content: "";
            width: 20px;
            height: 2px;
            border-radius: 25px;
            transform: scaleX(1);
            background:#e8d9cd;
            transform-origin: center;
            opacity: 1;
            transition: 0.3s ease-in-out, visibility ease-in;
        }

    }

    .logo-container{
        display: flex;
        text-decoration: none;
        color: #523d35;
        // margin-left: 1rem;
        align-items: center;
        
        img{
            width: 50px;
            height: 50px;
            padding-left: .8rem;
        }

        .logo{
            letter-spacing: 1px;
            display: flex;
            gap: 2px;
            font-weight: 1000;
            font-family: "Recursive", sans-serif;
            p{
            }
        }
    }

    .page-links{
        display: flex;
        list-style: none;
        gap: 1.5rem;
        justify-content: center;
        
        li{

            position: relative;

            &::after{
                position: absolute;
                bottom: -30%;
                left: 35%;
                margin: 0 auto;
                content: "";
                width: 20px;
                height: 2px;
                border-radius: 25px;
                transform: scaleX(0);
                background: #ff7e00;
                transform-origin: center;
                opacity: 0;
                transition: 0.3s ease-in-out, visibility ease-in;
            }

            &:hover::after{
                transform: scaleX(1);
                transform-origin: center;
                opacity: 1;
            }
        }
        
        a{
            color: black;
            font-weight: 400;
            text-decoration: none;
            padding: 2px;
        }



    }

    .app{
        display: flex;
        gap: .7rem;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2rem; 
        position: relative;

        .app-icon{
            font-size: 1.5rem;
          cursor: pointer;
          padding:  0.6rem;
          user-select: none;
        }

     
        .menu{
            transition: .5s ease-in-out;
            
            &:hover{
                transform: scale(1.1);
            }

        }
        
        .notifs{
            transition: 0.3s ease-in-out;
            
            &:hover{
                animation: shake 0.4s ;
            }
        }

        .cart{
            transition: 0.3s ease-in-out;

            &:hover{
                animation: shake 0.4s ;
            }
        }
        
        @keyframes shake {
            0% { transform: translateX(0); } /* Start position */
            25% { transform: translateX(-2px); } /* Move left */
            50% { transform: translateX(2px); } /* Move right */
            75% { transform: translateX(-2px); } /* Move left again */
            100% { transform: translateX(0); } /* Return to original position */
          }

        .login-btn{
            font-size: 16px;
            cursor: pointer;
            margin-left: .5rem;
            border: 1px solid transparent;
            transition: 0.3s ease;
            padding: 5px 8px;
            border-radius: 15px;
            border: 1px solid #8888;
            user-select: none;
            outline: none;
            text-decoration: none;
            color: #333;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 80px;

            &:hover{
                width: 100px;
                gap: 10px;
            }

            .logo-icon{
                font-size: 18px;
            }
        }
    }


}

.mobilee-menu-container{
    width: 100%;
    height: 100%;
    // overflow-x: hidden;
    z-index: 100;
    // background: rgba(0, 0, 0, 1);
    background: rgba(2, 2, 2, 0.001);
     backdrop-filter: blur(5px);
    display: none;
    transform-origin: center;
    transition: .4s ease-in-out;
    top: 0;
    // left: 0;
    position: fixed;
    
    
    .mobile-menu{
        // border: 1px solid red;
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        background: #fff;
        border-radius: 0px;  
        width: 350px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow: auto ;

        &::-webkit-scrollbar {
            display: none;
          }
          
        
        .menu-content{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-bottom: 6rem;

            .cancel-btn{
                top: 1.6rem;
                font-size: 30px;
                position: relative;
                left: 83%;
                cursor: pointer;
                transition: .3s ease-in-out;
                user-select: none;
                
                &:hover{
                    transform: scale(1.1);
                }
            }

            span{
                margin-top: 2rem;
                font-size: 30px;
                display: flex;
                gap: 1rem;

                .span-icon{
                    cursor: pointer;
                    &:hover{
                        animation: shake 0.4s ;
                    }
                }
            }

            .mobilepage-links{
                padding-top: 2rem;
                width: 99%;
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                list-style: none;
                gap: 1.5rem;
                font-size: 18px;

                li, .icon{
                    color: white;
                }

                .icon{
                    font-size: 30px;
                    color: #eee;
                }

                li{
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    bottom: 0;
                    height: 100%;
                }
                
                
                a{
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    background: rgba(0, 0, 0, 0.5) url('../../stock/backgrounds/049A2345.jpg')no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-blend-mode: overlay;
                    position: relative;
                    text-decoration: none;
                    transition: .3s ease;
                    color: #333;
                    font-weight: 500;
                    height: 6rem;
                    border-radius: .6rem;
                    padding: 1rem;
                    width: 80%;
                    margin: 0 auto;

                    &:nth-child(2){
                        background: rgba(0, 0, 0, 0.5) url('../../stock/grid/sobunsomze.jpg') no-repeat;
                        background-blend-mode: overlay;
                        background-size: cover;
                        background-position:center ;
                        position: relative;
                    }

                    &:nth-child(3){
                        background: rgba(0, 0, 0, 0.5) url('../../stock//backgrounds/049A2093.jpg');
                        background-position: 25rem;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;
                    }

                    &:nth-child(4){
                        background: rgba(0, 0, 0, 0.5) url('../../stock//backgrounds/049A2521.jpg');
                        // background-position:center;
                        background-size: cover;
                        background-blend-mode: overlay;
                        position: relative;
                    }
                    

                    &:hover{
                      width: 77%;
                    }
                }
            }
        }
    
}


}
.mobilee-menu-container.is-visible{
    display: block;
    transform-origin: center;
    transition: .4s ease-in-out;

}


@media screen and (max-width: 1050px) {
.navbar{
    border-radius: 50px;
    grid-template-columns: repeat(2, 1fr);


    .mobile-view{
        display: block;
    }
    .desktop-view{
        display: none;
    }

    .logo-container{
        margin-left: 0;
        font-size: smaller;
    }
    
    .page-links{
        display: none;
    }

    .app{
        gap: 0.3rem;
        margin-right: 1rem;

        .notif-bars{
            position: relative;
            display: none;
        }
    }
}
}