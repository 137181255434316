/* src/CountdownTimer.css */
.countdown {
    // display: grid;
    // grid-template-columns: repeat(4,1fr);
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    font-size: 2rem;
    font-weight: bold;
    // font-family: 'Courier New', Courier, monospace;
    color: #fff;
    // background: linear-gradient(45deg, #ff6b6b, #f06595, #00b4d8, #6a4c93);
    // background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    // text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    // background: rgba(2, 2, 2, 0.001);
    // backdrop-filter: blur(5px);

  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .countdown span {
    margin: 0 10px;
    animation: fadeIn 1s ease-in-out;
    // background: red;

    &:nth-child(2){
        // border-right: 1px solid #888;
        padding-right: 1rem;
    }
    &:nth-child(3){
        // border-right: 1px solid #888;
        padding-right: 1rem;
    }   &:nth-child(1){
        // border-right: 1px solid #888;
        padding-right: 1rem;
    }

    color: #f5f5f5;
    // font-size: 24px;
   letter-spacing: 1px;;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

@media screen and (max-width: 660px) {
.countdown{
    font-size: .9em;
}
}
  